<template>
  <b-nav-item-dropdown
    class="dropdown-cart mr-25 "
    menu-class="dropdown-menu-media"
    right
    id="dropDown-tag-a"
  >
    <template #button-content>
      <feather-icon
        :badge="cart.length"
        class="text-body"
        icon="ShoppingCartIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          My cart
        </h4>
        <b-badge
          pill
          variant="light-primary"
        >
          {{cart.length}} Products
        </b-badge>
      </div>
    </li>

    <!-- Cart Items -->
    <vue-perfect-scrollbar
      v-if="cart.length"
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <b-media
        v-for="item in cart.items"
        :key="item.folio"
      >
        <template #aside>
          <b-img
            v-if="item.modelType != 'booking'"
            :src="imgUrl + item.coverImage"
            @error="imgAlternativo"
            :alt="item.name"
            rounded
            width="62px"
          />
          <b-img
            v-if="item.modelType == 'booking'"
            :src="item.img"
            @error="imgAlternativo"
            :alt="item.name"
            rounded
            width="62px"
          />
        </template>
        <feather-icon
          icon="XIcon"
          class="cart-item-remove cursor-pointer"
          @click.stop="deleteItem(item)"
        />
        <div class="media-heading">
          <h6 class="cart-item-title">
            <b-link class="text-body">
              {{ item.detaildisplay }}
            </b-link>
          </h6>
          <small class="cart-item-by">{{ item.categoryName }}</small>
        </div>

        <div class="cart-item-qty ml-1">
          <b-form-spinbutton
            v-model="item.qty"
            min="0"
            size="sm"
            @change="changeQty($event, item)"  
            :readonly="item.categoryName == 'Transfer' || item.categoryName == 'Reservas'"
          />
        </div>

        <h5 class="cart-item-price">
          {{subTotalProductoQty(item)}}
        </h5>
      </b-media>
    </vue-perfect-scrollbar>
    <!-- Cart Footer -->
    <li
      v-if="cart.length"
      class="dropdown-menu-footer"
    >
    
      <div class="d-flex justify-content-between">
        <span class="mb-0">
          SubTotal:
        </span>
        <span class="text-primary mb-0">
          $ {{subTotal}}
        </span>
      </div>
      <div class="d-flex justify-content-between mb-1" style="border-bottom: 2px solid; border-color: #bdbdbd">
        <span class="mb-0">
          Discount:
        </span>
        <span class="text-primary mb-0">
          $ {{totalDiscountOnAllProducts}}
        </span>
      </div>
      <div class="d-flex justify-content-between mb-1">
        <h6 class="font-weight-bolder mb-0">
          Total:
        </h6>
        <h6 class="text-primary font-weight-bolder mb-0">
          $ {{total}}
        </h6>
      </div>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        :to="{ name: 'order-summary' }"        
      >
        Order Summary
      </b-button>
    </li>

    <p
      v-if="!cart.length"
      class="m-0 p-1 text-center cart-empty"
      
    >
      Your cart is empty
    </p>
  </b-nav-item-dropdown>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import { removeFromCartDL } from '@/helpers/dataLayerHelpers'

export default {
  components: {
    VuePerfectScrollbar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {    
      items: [],
      imgUrl: process.env.VUE_APP_BASE_URL_SERVER, 
      imdDefault: require('@/assets/images/default.jpg'),
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
    }
  },
  computed: {
    ...mapState('shop',['cart']),
    ...mapGetters('shop',['totalDiscountOnAllProducts']),
    subTotal(){
      return ((Math.round( (parseFloat(this.totalDiscountOnAllProducts) + parseFloat(this.cart.totalBreakdown.total)) * 100) / 100).toFixed(2) )                
    },
    total(){
      return ( (Math.round( (this.cart.totalBreakdown.total ) * 100) / 100).toFixed(2) )            
    }
  },
  methods: {
    ...mapMutations('shop',['removeProductFromCart','calculateTotal']),
    imgAlternativo( event ){
      event.target.src = this.imdDefault
    },
    subTotalProductoQty(item){      
      const subTotal = (Math.round( item.qty * ((item.discountApplied > 0 ) ? item.salepriceWithDiscount : item.saleprice)* 100) / 100).toFixed(2)      
      return subTotal
    },
    changeQty(qty, item){
      if(qty == 0 ){
        this.removeProductFromCart(item)    
        if( this.$gtm.enabled() ){
          removeFromCartDL(item)               
        }         
      }
      if( qty > 0){
        this.calculateTotal()
      }                       
    },
    deleteItem(item){
      this.removeProductFromCart(item)   
      if( this.$gtm.enabled() ){
        removeFromCartDL(item)                       
      }
              
    }, 
  },
}
</script>

<style lang="scss" scoped>
.dropdown-cart {
  .media {
    .media-aside {
      align-items: center;
    }    
  }  
}
.dark-layout .header-navbar .navbar-container .nav .dropdown-cart .dropdown-menu.dropdown-menu-media .dropdown-menu-header {
    border-bottom: 1px solid white;
    background-color: #243447;
}
.dark-layout .notification-title {
  color: white;
}
.dark-layout .header-navbar .navbar-container .nav .dropdown-cart .dropdown-menu.dropdown-menu-media .media {
    border-color: white;
}
.dark-layout .header-navbar .navbar-container .nav .dropdown-cart li {
  color: #fff;
  background-color: #243447;
}
//fondo del cart vacío
.dark-layout .cart-empty{
  background-color: #243447;
  color: white;
}
.dark-layout .badge.badge-light-primary {
  color: #ffffff!important;
  background-color: #999999;
}

</style>
